@media screen and (max-width: 1700px) {
  .conditions .col-1-4 h2 {
    font-size: 34px;
  }
}
@media screen and (max-width: 1500px) {
  .header .menu {
    padding-left: 0;
  }
  .conditions .col-1-4 h2 {
    font-size: 30px;
  }
  h1 {
    font-size: 56px;
  }
}
@media screen and (max-width: 1300px) {
  h1 {
    font-size: 48px;
  }
  .conditions .col-1-4 h2 {
    font-size: 28px;
  }
}
@media screen and (max-width: 1200px) {
  .conditions .col-1-4 h2 {
    font-size: 24px;
  }
  .logo {
    right: 20px;
  }
  .header .menu {
    padding: 0;
    font-size: 16px;
    a {
      margin-left: 10px;
    }
  }
  .header .col-2 .content {
    padding-right: 10px;
  }
  .Dropdown-control {
    padding: 0 30px 0 5px;
    font-size: 16px;
  }
  .conditions .col-1-4 .content {
    padding: 50px 16px 50px 16px;
  }
  .conditions .item {
    padding: 16px;
  }
  .conditions .item h3 {
    font-size: 18px;
  }
  .container {
    padding: 0 16px;
  }
  .vacancy-list .col-1, .vacancy-list .col-2 {
    padding: 24px 16px;
  }
  .vacancy-list .col-1 .item ul, .vacancy-list .col-2 .item ul {
    width: 70%;
  }
  .box-red h2 {
    font-size: 38px;
  }
}

@media screen and (max-width: 1100px) {
  .steps h3, p, ul,.ReactModal__Content .modal-body {
    font-size: 20px;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 22px;
  }
  h3, .carousel-wrap h3 {
    font-size: 20px;
  }
  .list-vacancy .item, .list-reviews .item {
    p, ul, div {
      font-size: 20px;
    }
  }
  .conditions .col-1-4 h2 {
    font-size: 22px;
  }
}

@media screen and (max-width: 940px) {
  .ReactModal__Overlay {
    overflow: hidden;
  }
  .ReactModal__Content {
    .modal-body {
      overflow-y: auto;
      max-height: calc(100dvh - 74px);
    }
  }
  .jobot-form-field-choice {
    flex-direction: column;
    & > div:last-child {
      width: 100%;
    }
  }
  .container {
    .list-vacancy,.list-reviews {
      .item {
        width: 100%;
        padding: 0;
      }
    }
  }
  .logo {
    bottom: -50px;
  }
  .header {
    .col-1 {
      min-height: initial;
      .content {
        padding: 40px 0 40px 16px;
        h1 {
          padding: 30px 12px 50px 0;
          font-size: 28px;
          line-height: 120%;
        }
      }
    }
  }
  .box-red {
    padding: 50px 0 70px;
    h2 {
      font-size: 30px;
      line-height: 120%;
      padding: 0;
    }
    p {
      font-size: 18px;
      line-height: 120%;
      margin: 0;
    }
  }
  .footer {
    padding: 30px 16px;
    .row {
      align-items: flex-start;
    }
    img {
      width: 100px;
    }
    span {
      font-size: 14px;
    }
  }
  .vacancy-list h3 {
    font-size: 24px;
  }
  .vacancy-list .title {
    font-size: 16px;
    line-height: 23px;
  }
  .conditions .col-1-4 {
    p {
      font-size: 14px;
    }
    h2 {
      font-size: 30px;
      br {
        display: none;
      }
    }
  }
  .vacancy-list .col-1 .item ul, .vacancy-list .col-2 .item ul {
    width: 60%;
  }
  .vacancy-list .col-1 .item ul, .vacancy-list .col-2 .item ul {
    font-size: 14px;
    line-height: 18px;
  }
  .vacancy-list .col-1 .content, .vacancy-list .col-2 .content {
    padding: 16px;
  }
  .vacancy-list h2 {
    font-size: 30px;
  }
  .vacancy-list .controls {
    padding: 0 0 44px;
  }
  .vacancy-list .col-1 .item, .vacancy-list .col-2 .item {
    padding: 16px 0 16px;
  }
  .index__formWrapper .index__formSection .index__formSectionRow > div > div > div {
    width: 100%;
  }
  .index__formWrapper form > div > div {
    .jobot-form-field-text, .jobot-form-field-date, .jobot-form-field-choice {
      width: 100%;
      label + div {
        width: 100%;
      }
    }
  }
  .conditions {
    .item {
      padding: 16px;
      width: 50%;
      height: initial;
      min-height: 50vw;
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 2;
      }
      &:nth-child(3) {
        order: 4;
      }
      &:nth-child(4) {
        order: 6;
      }
      &:nth-child(5) {
        order: 5;
      }
      &:nth-child(6) {
        order: 3;
      }
      &:nth-child(7) {
        order: 8;
      }
      &:nth-child(8) {
        order: 7;
      }
      &:nth-child(9) {
        order: 9;
      }
      &:nth-child(10) {
        display: block;
        order: 10;
      }
      h3 {
        font-size: 12px;
        line-height: 120%;
      }
      p {
        font-size: 14px;
        line-height: 120%;
      }
    }
    .row {
      flex-direction: column;
      .col-3-4 {
        width: 100%;
      }
      .col-1-4 {
        width: 100%;
        .content {
          flex-direction: column;
          padding: 30px 16px;
          h2 {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
  .index__amountField {
    min-width: 80px;
  }
}

@media screen and (max-width: 767px) {
  .row {
    flex-wrap: wrap;
    .col-1,.col-2 {
      width: 100%;
      padding: 0;
      align-items: center;
      margin: 0;
    }
    &.box {
      &.right-img {
        .col-1 {
          order: 2;
        }
        .col-2 {
          order: 1;
        }
      }
    }
  }
  h1 {
    font-size: 22px;
  }
  .wrap-form {
    margin-top: 0;
    .index__formWrapper {
      font-size: 18px;
      form {
        .jobot-form-field-choice {
          label {
            width: 100%;
          }
        }
        & > div > div {
          .index__error {
            position: static;
          }
          label {
            padding: 0;
            width: 100%;
            margin-bottom: 10px;
          }
          & > div:nth-child(2) {
            width: 100%;
          }
          .checkbox-wrapper {
            display: flex;
          }
        }
      }
    }
    h2 {
      padding: 16px;
    }
    .rc-checkbox-inner {
      width: 30px;
      height: 30px;
    }
    .rc-checkbox-checked .rc-checkbox-inner:after {
      left: 10px;
      top: 3px;
    }
    .btn, .index__formBtn {
      height: 40px;
      font-size: 16px;
      padding: 8px 20px;
    }
    h1 {
      padding: 0 16px;
      margin: 40px 0 30px;
    }
  }
  .footer {
    .row {
      flex-wrap: wrap;
      flex-direction: column;
      div {
        margin: 8px 0;
      }
    }
  }
  .logo {
    width: 50px;
  }
  .header {
    min-height: 110px;
  }
  .index__formWrapper .jobot-forms__formDateSelect input {
    min-width: 60px;
  }
  .index__formDateSelect {
    input {
      margin-right: 5px;
    }
  }
  #birthDate-month {
    width: 130px;
    min-width: 130px;
    margin-right: 5px;
  }
  .about h1 {
    margin: 40px 0 30px;
  }
  .btn, .index__formBtn {
    height: 40px;
    font-size: 16px;
    padding: 8px 20px;
  }
  .carousel.carousel-slider {
    height: 300px;
  }
  .ReactModal__Content {
    h3 {
      font-size: 24px;
      margin: 30px 0 20px;
    }
  }
  h2 {
    font-size: 18px;
    padding: 16px;
  }
  .box1 {
    margin-bottom: 0;
    .col-1 {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
  .btn-close {
    right: 5px;
  }
  .scroll-container {
    bottom: 80px!important;
  }
  .vacancy-list .col-1, .vacancy-list .col-2 {
    margin: 0;
  }
  .jobot-form-field-file .index__formBtn{
    height: 46px;
    font-size: 18px;
    line-height: 1.1;
    // padding: 2px 20px;
    // display: inline-flex;
    // align-items: center;
  }
  .index__fileControls {
    flex-wrap: wrap;
  }
  .index__formWrapper .jobot-forms__formDateSelect > div {
    margin-right: 5px;
      width: auto;
      min-width: 100px;
      flex: 1;
  }
  .index__formWrapper input {
    font-size: 16px;
  }
  .jobot-forms-rc-select-single {
    &:not(.jobot-forms-rc-select-customize-input) {
      .jobot-forms-rc-select-selector {
        .jobot-forms-rc-select-selection-item {
          max-width: calc(100% - 24px);
        }
      }
    }
  }
  .conditions .item {
    padding: 12px;
  }
  .vacancy-list .col-1 .item ul {
    padding-left: 12px;
    width: 65%;
  }
  .vacancy-list .title {
    font-size: 14px;
  }
  .index__fileItem {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .index__fileList .index__fileButtonGroup {
    margin-left: 0;
  }
}

@media screen and (max-width: 500px) {
  .wrap-form, .about {
    h1 {
      margin: 10px 0 40px;
    }
  }
  .index__formWrapper h2 {
    margin: 30px 0 10px;
    padding: 0;
    font-size: 22px;
  }
  .list-vacancy .item, .list-reviews .item {
    p, ul, div {
      font-size: 18px;
    }
  }
}


@media screen and (max-width: 326px) {
  .btn-arrow {
    margin-left: 10px;
  }
  .controls .btn {
    margin-bottom: 20px;
  }
}
